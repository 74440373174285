body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
}

.test-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.question-card {
  width: 500px;
  text-align: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.answers {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.answer-button {
  width: 100%;
}

.result-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.result-card {
  width: 400px;
  text-align: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}